import style from '../css/expirence.module.scss'
import {useTranslation} from "react-i18next";

const Experience = () => {
    const { t, i18n } = useTranslation();

    return(
        <div className={style.experience}>
            <h3 className={style.title}>{t('experience.title')}</h3>
            <h3 className={style.experienceTitle}>A.G. Development | 06.2021 - 06.2022</h3>
            <h3 className={style.experienceTitle}>BandIT House | 07.2022 - 10.2022</h3>
            <h3 className={style.experienceTitle}>MyBox | 12.2022 - 04.2023</h3>
            <h3 className={style.experienceTitle}>Broadway Beauty | 06.2023 - Obecnie</h3>
        </div>
    )
}

export default Experience;